<template>
  <div>
    <div class="section band-hero">
      <div class="row">
        <div class="col span_1">
          <h1 class="heading-hero-alt">Exclusive Monthly Marketing Resources </h1>
          <div class="heading-tertiary">Take advantage of Roadside's tools to chart your marketing path.</div>
        </div>
      </div>
    </div>
    <div id="team_training" class="section band-light" v-if="this.$store.getters.getLimited && this.$store.getters.getLimited.length > 0">
      <div class="section-title">
        <div class="heading-primary">This Month's Team Training</div>
        <div v-for="limit in this.$store.getters.getLimited.slice(0, 1)" :key="limit.id" class="section-counter">
          <Counter :year="[limit.year]" :month="[limit.month]" :date="[limit.day]" :hour="[limit.hour]" :minute="[limit.min]" :second="0" :millisecond="0" />
        </div>
      </div>
      <div v-for="limit in this.$store.getters.getLimited.slice(0, 1)" :key="limit.id">
        <div class="row">
          <div class="col span_1_2 align-center">
              <YouTube :videoid="[limit.vid]" :img="[limit.img]" ></YouTube>
          </div>
          <div class="col span_1_2" v-html="limit.desc">
          </div>
        </div>
      </div>
    </div>
    <div id="coaching_diy" class="section">
      <div class="section-title">
        <div class="heading-primary" style="margin-bottom: 0;">Coaching DIY</div>
        <div class="heading-tertiary" style="margin-top: 0;">Pick your pain point and get DIY coaching to overcome any barrier!</div>
      </div>
      <div class="row">
        <div class="col span_1">
          <div class="diy-wrap"  v-if="this.$store.getters.getPainpoints && this.$store.getters.getPainpoints.length > 0">
            <div v-for="diyitem in this.$store.getters.getPainpoints" :key="diyitem.id" class="diy-item-wrap">
              <router-link :to="'/diy-coaching/' + [diyitem.slug]" class="diy-item">
                <i :class="[diyitem.icon]"></i>
                <div class="diy-item-title">{{diyitem.title}}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  class="section band-light">
      <div class="row">
        <div class="col span_1_2" id="weekly_coach">
          <div class="section-title">
            <div class="heading-tertiary">This Week's Weekly Coach</div>
            <div class="section-counter">
              <Counter :year="[next_wc_year]" :month="[next_wc_month]" :date="[next_wc_day]" :hour="5" minute="59" second="0" millisecond="0" />
            </div>
          </div>
          <div>
            <div class="row">
              <router-link to="/weeklycoach" class="img-link">
              <img src="@/assets/weekly-coach-banner.png" alt="Weekly Coach Banner" class="fullwidth" />
              </router-link>
            </div>
          </div>
        </div>
        <div class="col span_1_2" id="quarterly">
          <div class="section-title">
            <div class="heading-tertiary">This Quarter's Game Plan</div>
            <div class="section-counter">
              <Counter :year="[quarter_count_year]" :month="[quarter_count_month]" :date="[quarter_count_day]" :hour="0" minute="01" second="0" millisecond="0" />
            </div>
          </div>
          <div>
            <div class="row">
              <a :href="[current_quarter]" target="_blank" class="img-link">
                <img src="@/assets/quarter-game-plan.png" alt="This Quarter Game Plan" class="fullwidth" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="upcoming_events" class="section band-light">
      <div class="section-title">
        <div class="heading-primary">Upcoming Events</div>
      </div>
      <div>
        <div class="row">
        <div class="col span_1">
          <div class="responsive-course-table">
            <div class="rct__table">
              <div class="rct__row rct__header">
                <div class="rct__cell rct__date">
                  Date
                </div>
                <div class="rct__cell rct__speaker">
                  Speaker(s)
                </div>
                <div class="rct__cell rct__topic">
                  Topic
                </div>
                <div class="rct__cell rct__link">
                  Register
                </div>
              </div>
            </div>
            <div class="rct__table" v-if="this.$store.getters.setItems && this.$store.getters.setItems.length > 0">
              <div class="rct__row" v-for="item in this.$store.getters.setItems" :key="item.id">
                <div class="rct__cell rct__date" data-title="Date">
                  {{item.date}}
                </div>
                <div class="rct__cell rct__speaker" data-title="Speaker(s)">
                  {{item.speaker}}
                </div>
                <div class="rct__cell rct__topic" data-title="Topic">
                  {{item.title}}
                </div>
                <div class="rct__cell rct__link" data-title="Register">
                  <a :href="[item.url]" target="_blank">Click to Register</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth';

import YouTube from '@/components/YouTube.vue'

import Counter from '@/components/Counter.vue'

export default {
  metaInfo: {
    title: 'DIY Marketing Resources APP | Welcome'
  },
  data: function() {

    return {
      lc_title : '',
      lc_vid: '',
      lc_year: '',
      lc_month: '',
      lc_day: '',
      lc_hour: '',
      lc_min: '',
      lc_desc: '',
      lc_img: '',
      current: Date.now(),
      user: null,
      next_wc_year: '',
      next_wc_month: '',
      next_wc_day: '',
      current_month: '',
      current_year: '',
      current_quarter: '',
      next_quarter: '',
      quarter_count_year: '',
      quarter_count_month: '',
      quarter_count_day: ''
    }
  },
  created() {
    this.$store.dispatch('getLimited')
    this.$store.dispatch('setItems')
    this.$store.dispatch('getPainpoints')

    let date = new Date();
    let monday = new Date(date.setDate(date.getDate() + (7-date.getDay())%7+2));
    this.next_wc_year = monday.getFullYear();
    this.next_wc_month = monday.getMonth();
    this.next_wc_day = monday.getDate();

    this.current_month = date.getMonth();
    this.current_year = date.getFullYear();
    this.next_year = this.current_year + 1;
    switch (this.current_month)
      {
        case 0: case 1: case 2:
          this.current_quarter = "https://www.roadsidedentalmarketing.com/qrms/quarter-1-" + this.current_year + "/";
          this.next_quarter =  "https://www.roadsidedentalmarketing.com/qrms/quarter-1-" + this.current_year + "/";
          this.quarter_count_year = this.current_year;
          this.quarter_count_month = 3;
          this.quarter_count_day = 1;
          break;
        case 3: case 4: case 5:
          this.current_quarter = "https://www.roadsidedentalmarketing.com/qrms/quarter-2-" + this.current_year + "/";
          this.next_quarter =  "https://www.roadsidedentalmarketing.com/qrms/quarter-1-" + this.current_year + "/";
          this.quarter_count_year = this.current_year;
          this.quarter_count_month = 6;
          this.quarter_count_day = 1;
          break;
        case 6: case 7: case 8:
          this.current_quarter = "https://www.roadsidedentalmarketing.com/qrms/quarter-3-" + this.current_year + "/";
          this.next_quarter =  "https://www.roadsidedentalmarketing.com/qrms/quarter-1-" + this.current_year + "/";
          this.quarter_count_year = this.current_year;
          this.quarter_count_month = 9;
          this.quarter_count_day = 1;
          break;
        case 9: case 10: case 11:
          this.current_quarter = "https://www.roadsidedentalmarketing.com/qrms/quarter-4-" + this.current_year + "/";
          this.next_quarter =  "https://www.roadsidedentalmarketing.com/qrms/quarter-1-" + this.next_year + "/";
          this.quarter_count_year = this.next_year;
          this.quarter_count_month = 0;
          this.quarter_count_day = 1;
          break;
      }
  },
  computed: {
    
  },
  components: {
    YouTube,
    Counter
  }
}
</script>
<template>
  <div class="icon">
  <img :src="require('@/img/icons/' + name + '.svg')">
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'strike'
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 .3rem;
  top: -.05rem;
  fill: currentColor;
}
</style>
<template>
  <div v-if='loaded' class="countdown-wrap">
    <div>
      {{displayDays}}
      <span>D</span>
    </div>
    <div>
      {{displayHours}}
      <span>H</span>
    </div>
    <div>
      {{displayMinutes}}
      <span>M</span>
    </div>
    <div>
      {{displaySeconds}}
      <span>S</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Counter',
  props: [
    'year',
    'month',
    'date',
    'hour',
    'minute',
    'second',
    'millisecond'
  ],
  data: function() {
    return {
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
      currentDate: '',
      endDate: '',
      loaded: false
    }
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60
    },
    _hours() {
      return this._minutes * 60
    },
    _days() {
      return this._hours * 24
    },
    end() {
      return new Date(
        this.year,
        this.month,
        this.date,
        this.hour,
        this.minute,
        this.second,
        this.millisecond
      );
    }
  },
  mounted() {
    this.showRemaining()
  },
  methods: {
    formatNum: num => (num < 10 ? "0" + num : num),
    showRemaining() {
      this.currentDate = new Date();
      this.endDate = new Date(2020,8,11,10, 10, 10, 10);
      const now = new Date();
      const distance = this.end.getTime() - now.getTime();
      const timer = setInterval(() => {
        const now = new Date();
        //const end = new Date(2020,7,11,10, 10, 10, 10);
        const distance = this.end.getTime() - now.getTime();
        if(distance < 0 ) {
          clearInterval(timer);
          this.persisted.str = "dead"
          return
        }
        if(distance > 1 ) {
          this.persisted.str = "live"
        }

        const days = Math.floor((distance / this._days));
        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);
        this.displayMinutes = this.formatNum(minutes);
        this.displaySeconds = this.formatNum(seconds);
        this.displayHours = this.formatNum(hours);
        this.displayDays = this.formatNum(days);
        this.loaded = true

      }, 1000)
    }
  }
}
</script>
<style lang="scss">
  .countdown-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 600px;
    margin: 0 auto 30px;
    > div {
      width: 25%;
      font-size: 70px;
      @media(max-width:680px) {
        width: 50%;
        font-size: 24px;
      }
      span {
        display: block;
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        opacity: .7;
        @media(max-width:680px) {
          font-size: 14px;
        }
      }
    }
  }
</style>